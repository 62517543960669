import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import BackButton from './BackButton';
import ContinueButton from './ContinueButton';
import ApiError from './ApiError';

const Step3 = ({
    step, setStep, data, setData, setLoading
}) => {
    const {
        setValue,
        getValues,
        watch,
        trigger,
        register,
        formState: { errors }
    } = useForm({
        mode: 'onChange'
    });

    const password = useRef({});
    password.current = watch('password', '');

    const [otpInvalid, setOtpInvalid] = useState(false);
    const [apiError, setApiError] = useState(false);

    const ValidateCode = async () => {
        const isValid = await trigger();

        // if all is ok move to next step
        if (isValid) {
            setLoading(true);
            const values = getValues();

            // api call
            const apiUrl = process.env.GATSBY_API_PREFIX + '/Registration/ValidateCode';
            const apiData = {
                otp: values.otp,
                sessionId: data.sessionId
            };

            const formData = new FormData();
            Object.keys(apiData).forEach((key) => formData.append(key, apiData[key]));

            const response = await axios.post(apiUrl, formData)
                .catch((err) => {
                    setLoading(false);
                    setApiError(true);
                    throw (err);
                });

            if (response && response.data.success === false) {
                setValue('otp', '', { shouldValidate: true });
                setOtpInvalid(true);
            }

            if (response && response.data.success === true) {
                // save form data to state
                setData({
                    ...data,
                    otp: values.otp,
                    password: values.password
                });

                // if all ok move to next step
                setOtpInvalid(false);
                if (response) setStep((cur) => cur + 1);
            }

            setLoading(false);
        }
    };

    return (
        <section className={step === 3 ? 'form-section active' : 'form-section'}>
            <h2>Account Security</h2>

            <div className={errors.otp ? 'form-group has-error' : 'form-group'}>
                <label htmlFor="otp">Registration Code</label>
                <input
                    id="otp"
                    name="otp"
                    type="number"
                    className="form-control"
                    autoComplete="off"
                    onFocus={() => {
                        setOtpInvalid(false);
                    }}
                    {...register('otp', { required: true })}
                />
                {otpInvalid && <small className="form-text error-text" role="alert">There was a problem with your registration code.</small>}
                {errors.otp && <small className="form-text error-text" role="alert">Please enter your registration code.</small>}
                <small className="form-text">This code will have been sent to the previously entered mobile number.</small>
            </div>

            <div className={errors.password ? 'form-group has-error' : 'form-group'}>
                <label htmlFor="password">Password</label>
                <input
                    id="password"
                    name="password"
                    type="password"
                    className="form-control"
                    {...register('password', {
                        required: 'Please enter a password.',
                        minLength: {
                            value: 8,
                            message: 'Password must have at least 8 characters.'
                        }
                    })}
                />
                {errors.password && <small className="form-text error-text" role="alert">{errors.password.message}</small>}
                <small className="form-text">Minimum 8 characters.</small>
            </div>

            <div className={errors.confirm ? 'form-group has-error' : 'form-group'}>
                <label htmlFor="confirm">Confirm Password</label>
                <input
                    id="confirm"
                    name="confirm"
                    type="password"
                    className="form-control"
                    {...register('confirm', {
                        validate: (value) => value === password.current || 'The passwords do not match.'
                    })}
                />
                {errors.confirm && <small className="form-text error-text" role="alert">{errors.confirm.message}</small>}
            </div>

            {apiError && <ApiError />}

            <footer>
                <BackButton step={step} setStep={setStep} />
                <ContinueButton onClick={ValidateCode} />
            </footer>
        </section>
    );
};

export default Step3;
