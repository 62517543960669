import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default function CheckoutForm({ secret, data, setData }) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const clientSecret = secret;
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: "#333",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#333",
        },
      },
      invalid: {
        color: "#ff0000",
        iconColor: "#ff0000",
      },
    },
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      setData({ ...data, paymentComplete: true });
    }
  };

  return (
    <form id="payment-form" className="stripe-form" onSubmit={handleSubmit}>
      {!succeeded && (
        <>
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />
          <button
            type="submit"
            disabled={processing || disabled || succeeded}
            id="submit"
          >
            <span id="button-text">
              {processing ? (
                <div className="spinner" id="spinner" />
              ) : (
                "Pay now"
              )}
            </span>
          </button>
        </>
      )}
      {/* Show any error that happens when processing the payment */}
      {error && (
        <small className="error-text" role="alert">
          {error}
        </small>
      )}
      {/* Show a success message upon completion */}
      <div className={succeeded ? "success-message" : "success-message hidden"}>
        <p>
          <strong>Thank you for your payment.</strong>
        </p>
      </div>
    </form>
  );
}
