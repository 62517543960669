import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import BackButton from './BackButton';
import ContinueButton from './ContinueButton';
import ApiError from './ApiError';

const Step2 = ({
    step, setStep, data, setData, setLoading, setProducts
}) => {
    const {
        getValues,
        setValue,
        trigger,
        register,
        formState: { errors }
    } = useForm({
        mode: 'onChange'
    });

    const [emailInUse, setEmailInUse] = useState(false);
    const [couldNotSendOtp, setCouldNotSendOtp] = useState(false);
    const [apiError, setApiError] = useState(false);

    const initRegistration = async () => {
        // trigger validation
        const isValid = await trigger();

        if (isValid) {
            setLoading(true);
            setEmailInUse(false);
            setCouldNotSendOtp(false);
            const values = getValues();

            // api call
            const apiUrl = process.env.GATSBY_API_PREFIX + '/Registration/InitRegistration';
            const apiData = {
                mobileNumber: values.mobile,
                email: values.email
            };

            const formData = new FormData();
            Object.keys(apiData).forEach((key) => formData.append(key, apiData[key]));

            const response = await axios.post(apiUrl, formData)
                .catch((err) => {
                    setLoading(false);
                    setApiError(true);
                    throw (err);
                });

            if (response && response.data.success === false) {
                const { reason } = response.data;

                if (reason === 'EmailInUse') {
                    setValue('email', '', { shouldValidate: true });
                    setEmailInUse(true);
                }

                if (reason === 'CouldNotSendOtp') {
                    setValue('mobile', '', { shouldValidate: true });
                    setCouldNotSendOtp(true);
                }
            }

            if (response && response.data.success === true) {
                // save form data to state
                setData({
                    ...data,
                    sessionId: response.data.sessionId,
                    person: values.person,
                    email: values.email,
                    mobile: values.mobile,
                    landline: values.landline
                });

                const { products } = response.data;

                for (let i = 0; i < products.length; i += 1) {
                    if (products[i].productId === 1) {
                        products[i].name = 'Reserved';
                        products[i].description = 'Take table reservations on your website';
                        products[i].htmlId = 'reserved';
                    }

                    if (products[i].productId === 2) {
                        products[i].name = 'Ordered';
                        products[i].description = 'Click & collect food ordering system for your website';
                        products[i].htmlId = 'ordered';
                    }

                    if (products[i].productId === 3) {
                        products[i].name = 'VIP Bundle';
                        products[i].description = 'Both Reserved & Ordered combined in one subscription';
                        products[i].htmlId = 'vip-bundle';
                    }
                }

                setProducts(products);

                // if all ok move to next step
                setEmailInUse(false);
                if (response) setStep((cur) => cur + 1);
            }

            setLoading(false);
        }
    };

    return (
        <section className={step === 2 ? 'form-section active' : 'form-section'}>
            <h2>Contact Details</h2>

            <div className={errors.person ? 'form-group has-error' : 'form-group'}>
                <label htmlFor="person">Your Name</label>
                <input
                    id="person"
                    name="person"
                    type="text"
                    className="form-control"
                    {...register('person', { required: 'Please enter your name.' })}
                />
                {errors.person && <small className="form-text error-text" role="alert">{errors.person.message}</small>}
            </div>

            <div className={errors.email ? 'form-group has-error' : 'form-group'}>
                <label htmlFor="email">Email Address</label>
                <input
                    id="email"
                    name="email"
                    type="email"
                    className="form-control"
                    onFocus={() => {
                        setEmailInUse(false);
                    }}
                    {...register('email', {
                        required: true,
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    })}
                />
                {emailInUse && <small className="form-text error-text" role="alert">The email you entered is already in use.</small>}
                {errors.email && <small className="form-text error-text" role="alert">Please enter a valid email address.</small>}
                <small className="form-text">Confirmation will be sent to this email address.</small>
            </div>

            <div className={errors.mobile ? 'form-group has-error' : 'form-group'}>
                <label htmlFor="mobile">Mobile Number</label>
                <input
                    id="mobile"
                    name="mobile"
                    type="number"
                    className="form-control"
                    {...register('mobile', {
                        required: 'Please enter your mobile number.',
                        minLength: {
                            value: 9,
                            message: 'You mobile number must contain at least 10 numbers.'
                        }
                    })}
                />
                {couldNotSendOtp && <small className="form-text error-text" role="alert">We could not send an SMS message to the number you provided.</small>}
                {errors.mobile && <small className="form-text error-text" role="alert">{errors.mobile.message}</small>}
                <small className="form-text">A registration code will be sent to this number.</small>
            </div>

            <div className={errors.landline ? 'form-group has-error' : 'form-group'}>
                <label htmlFor="landline">Landline Number</label>
                <input
                    id="landline"
                    name="landline"
                    type="number"
                    className="form-control"
                    {...register('landline', {
                        required: 'Please enter your landline number.',
                        minLength: {
                            value: 9,
                            message: 'Your landline must contain at least 9 numbers.'
                        }
                    })}
                />
                {errors.landline && <small className="form-text error-text" role="alert">{errors.landline.message}</small>}
            </div>

            {apiError && <ApiError />}

            <footer>
                <BackButton step={step} setStep={setStep} />
                <ContinueButton onClick={initRegistration} />
            </footer>
        </section>
    );
};

export default Step2;
