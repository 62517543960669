import React from 'react';

const ApiError = () => (
    <p className="alert error-text" role="alert">
        There was a problem completing your registration, please try again.<br />
        If the problem persists please <a href="mailto:info@vip-dining.co.uk">contact us</a>.
    </p>
);

export default ApiError;
