import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

import BackButton from "./BackButton";
import StripeLogo from "../images/stripe.png";
import ApiError from "./ApiError";

const stripeKey = process.env.GATSBY_STRIPE_KEY;

const Step5 = ({ step, setStep, data, setData, setLoading }) => {
  const { register } = useForm({
    mode: "onChange",
  });

  const [apiError, setApiError] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(stripeKey)
  );

  const FinishRegistration = async () => {
    setApiError(false);
    setLoading(true);
    // api call
    const apiUrl = process.env.GATSBY_API_PREFIX + "/Registration/FinishRegistration";
    const apiData = {
      sessionId: data.sessionId,
      restaurantName: data.restaurant,
      name: data.person,
      landPhoneNumber: data.landline,
      password: data.password,
      address1: data.address1,
      address2: data.address2,
      town: data.town,
      county: data.county,
      country: data.country,
      postcode: data.postcode,
    };

    const formData = new FormData();
    Object.keys(apiData).forEach((key) => formData.append(key, apiData[key]));

    const response = await axios.post(apiUrl, formData).catch((err) => {
      setLoading(false);
      setApiError(true);
      throw err;
    });

    if (response && response.data.success === true) {
      // save form data to state
      setData({
        ...data,
        registered: true,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (data.paymentComplete) FinishRegistration();
  }, [data.paymentComplete]);

  return (
    <section className={step === 5 ? "form-section active" : "form-section"}>
      <h2>Summary and Payment</h2>

      <div className="summary">
        <h3>Restaurant Details</h3>
        <p>{data.restaurantName}</p>
        <p>{data.restaurantDisplayAddress}</p>
      </div>

      <div className="summary">
        <h3>Contact Details</h3>
        <p>
          {data.person}, {data.email}
        </p>
        <p>{data.mobile}</p>
        <p>{data.landline}</p>
      </div>

      <div className="summary">
        <h3>Product</h3>
        <p>
          '{data.productName}' for £{data.productPrice} a year
        </p>
      </div>

      {!data.paymentComplete && (
        <div className="form-check terms-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="terms"
            name="terms"
            onClick={() => {
              setTermsAccepted(!termsAccepted);
            }}
            {...register("terms", { required: true })}
          />
          <label className="form-check-label" htmlFor="terms">
            I agree to the{" "}
            <a
              href="https://www.itisreserved.com/SubscriptionTerms"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
          </label>
        </div>
      )}

      {termsAccepted && !data.paymentComplete && (
        <div className="payment">
          <div>
            Please enter your payment details below for your first year's
            payment of
            <strong> £{data.productPrice}</strong>.
          </div>
        </div>
      )}

      {termsAccepted && !data.paymentComplete && (
        <div className="payment">
          <Elements stripe={stripePromise}>
            <CheckoutForm
              secret={data.clientSecret}
              data={data}
              setData={setData}
            />
          </Elements>
          <img className="stripe-logo btn-next" src={StripeLogo} alt="" />
        </div>
      )}

      {data.paymentComplete && (
        <div className="success-message">
          <p>
            <strong>Thank you for your payment of £{data.productPrice}.</strong>
          </p>
        </div>
      )}

      {data.paymentComplete && data.registered && (
        <div className="message">
          <p>
            Your registration is complete. Pleases check your email for details
            of what to do next.
          </p>
        </div>
      )}

      {apiError && <ApiError />}

      <footer>
        {!data.paymentComplete && <BackButton step={step} setStep={setStep} />}
        {data.paymentComplete && !data.registered && (
          <button
            type="button"
            className="btn btn-primary btn-next"
            onClick={FinishRegistration}
          >
            Try again
          </button>
        )}
      </footer>
    </section>
  );
};

export default Step5;
