import React, { useState, useRef } from "react";

import Loader from "./Loader";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    sessionId: "",
    restaurantName: "",
    restaurantDisplayAddress: "",
    person: "",
    email: "",
    mobile: "",
    landline: "",
    otp: "",
    password: "",
    addressId: "",
    address1: "",
    address2: "",
    town: "",
    county: "",
    country: "",
    postcode: "",
    registered: false,
    productId: "",
    productName: "",
    productPrice: "",
    id: "",
    isEligible: "",
    clientSecret: "",
    paymentComplete: false,
  });
  const [products, setProducts] = useState([]);

  const form = useRef(null);

  return (
    <>
      {loading && <Loader />}

      {step < 5 && <div className="step">step {step} of 5</div>}

      <form ref={form} action="">
        <Step1
          step={step}
          setStep={setStep}
          data={data}
          setData={setData}
          setLoading={setLoading}
        />
        <Step2
          step={step}
          setStep={setStep}
          data={data}
          setData={setData}
          setLoading={setLoading}
          setProducts={setProducts}
        />
        <Step3
          step={step}
          setStep={setStep}
          data={data}
          setData={setData}
          setLoading={setLoading}
          products={products}
        />

        <Step4
          step={step}
          setStep={setStep}
          data={data}
          setData={setData}
          setLoading={setLoading}
          products={products}
        />
      </form>

      <Step5
        step={step}
        setStep={setStep}
        data={data}
        setData={setData}
        setLoading={setLoading}
      />
    </>
  );
};

export default Register;
