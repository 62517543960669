import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import ContinueButton from "./ContinueButton";
import ApiError from "./ApiError";

const loqateFindUrl =
  "https://api.addressy.com/Capture/Interactive/Find/v1.00/json3.ws";

const Step1 = ({ step, setStep, data, setData, setLoading }) => {
  const {
    getValues,
    setValue,
    trigger,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [apiError, setApiError] = useState(false);
  const [postcodeNotFound, setPostcodeNotFound] = useState(false);
  const [addresses, setAddresses] = useState([]);

  if (process.env.GATSBY_USE_LOQATE_STUB === 'true') {
    console.log("Using Loqate stub data");
  }

  const getAddressContainer = async (postcode) => {
    // api call to get a container id
    // which is used to get list of addresses
    const response = await axios
      .get(
        `${loqateFindUrl}?Key=${process.env.GATSBY_LOQATE_KEY}&Text=${postcode}&Countries=GB&Origin=GB&Language=en-GB`
      )
      .catch((err) => {
        setLoading(false);
        setApiError(true);
        throw err;
      });

    // Test for an error
    if (
      response.data.Items.length === 1 &&
      typeof response.data.Items[0].Error !== "undefined"
    ) {
      setLoading(false);
      setApiError(true);
      throw response.data.Items[0].Description;
    }

    if (
      response.data.Items.length === 0 ||
      response.data.Items[0].Type !== "Postcode"
    ) {
      setPostcodeNotFound(true);
      setValue("postcode", "", { shouldValidate: true });
      return false;
    }

    return response.data.Items[0].Id;
  };

  const getAddresses = async (container) => {
    const values = getValues();
    // api call to get list of addresses
    const response = await axios
      .get(
        `${loqateFindUrl}?Key=${process.env.GATSBY_LOQATE_KEY}&Text=${values.postcode}&Container=${container}`
      )
      .catch((err) => {
        setLoading(false);
        setApiError(true);
        throw err;
      });
    return response.data.Items;
  };

  const addressLookup = async () => {
    const isValid = await trigger("postcode");

    if (isValid) {
      setLoading(true);
      setPostcodeNotFound(false);
      setAddresses([]);
      setValue("address", "");
      const values = getValues();

      const addressContainer = await getAddressContainer(values.postcode);
      const addressList = await getAddresses(addressContainer);

      setAddresses(addressList);
      setLoading(false);
    }
  };

  const setRestaurantDetails = async () => {
    // trigger validation
    const isValid = await trigger();

    if (isValid) {
      const values = getValues();

      // save form data to state
      setData({
        ...data,
        restaurantName: values.restaurant,
        addressId: values.address,
      });

      setStep((cur) => cur + 1);
    }
  };

  return (
    <section className={step === 1 ? "form-section active" : "form-section"}>
      <h2>Restaurant Details</h2>

      <div
        className={errors.restaurant ? "form-group has-error" : "form-group"}
      >
        <label htmlFor="restaurant">Restaurant Name</label>
        <input
          id="restaurant"
          name="restaurant"
          type="text"
          className="form-control"
          {...register("restaurant", { required: true })}
        />
        {errors.restaurant && (
          <small className="form-text error-text" role="alert">
            Please enter the restaurant's name.
          </small>
        )}
      </div>

      <div className={errors.postcode ? "form-group has-error" : "form-group"}>
        <label htmlFor="confirm">Restaurant Postcode</label>
        <div className="form-inline">
          <input
            id="postcode"
            name="postcode"
            type="text"
            className="form-control"
            autoComplete="off"
            {...register("postcode", {
              required: true,
              pattern: /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i,
            })}
          />
          <button
            type="button"
            className="btn btn-primary btn-lookup"
            onClick={addressLookup}
          >
            Find address
          </button>
        </div>
        {postcodeNotFound && (
          <small className="form-text error-text" role="alert">
            Sorry, there were no addresses found for that postcode.
          </small>
        )}
        {errors.postcode && (
          <small className="form-text error-text" role="alert">
            Please enter a valid postcode.
          </small>
        )}
      </div>

      <div className={errors.address ? "form-group has-error" : "form-group"}>
        <label
          htmlFor="address"
          className={addresses.length === 0 ? "disabled" : ""}
        >
          Restaurant Address
        </label>
        <select
          className="form-control"
          id="address"
          name="address"
          disabled={addresses.length === 0}
          {...register("address", { required: true })}
        >
          <option value="">-- Please select --</option>
          {addresses.map((address) => (
            <option key={address.Id} value={address.Id}>
              {address.Text}, {address.Description}
            </option>
          ))}
        </select>
        {errors.address && (
          <small className="form-text error-text" role="alert">
            Please select an address.
          </small>
        )}
      </div>

      {apiError && <ApiError />}

      <footer>
        <ContinueButton onClick={setRestaurantDetails} />
      </footer>
    </section>
  );
};

export default Step1;
