import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import BackButton from "./BackButton";
import ContinueButton from "./ContinueButton";
import ApiError from "./ApiError";

import addressDetailsStub from "../stub/addressDetails";

const loqateRetrieveUrl =
  "https://api.addressy.com/Capture/Interactive/Retrieve/v1.20/json3.ws";

const Step4 = ({ step, setStep, data, setData, setLoading, products }) => {
  const {
    getValues,
    trigger,
    register,
    formState: { errors },
  } = useForm();

  // This costs money so only call on production!
  const lookupAddressDetails = async (addressId) => {
    // api call to get address details
    const response = await axios
      .get(
        `${loqateRetrieveUrl}?Key=${process.env.GATSBY_LOQATE_KEY}&Id=${addressId}`
      )
      .catch((err) => {
        setLoading(false);
        setApiError(true);
        throw err;
      });

    // Test for an error
    if (
      response.data.Items.length === 1 &&
      typeof response.data.Items[0].Error !== "undefined"
    ) {
      setLoading(false);
      setApiError(true);
      throw response.data.Items[0].Description;
    }

    return response.data.Items[0];
  };

  const getAddressDetails = async () => {
    let addressDetails;
    // get address details
    if (process.env.GATSBY_USE_LOQATE_STUB === 'true') {
      addressDetails = addressDetailsStub[0];
    } else {
      // This costs money so only call on production!
      // Only called after the duplicate email address check
      // and the OTP sending are successful
      addressDetails = await lookupAddressDetails(data.addressId);
    }

    return addressDetails;
  };

  const [otpNotValidated, setOtpNotValidated] = useState(false);
  const [apiError, setApiError] = useState(false);

  const createRegistrationPayment = async () => {
    const isValid = await trigger();

    // if all is ok move to next step
    if (isValid) {
      setLoading(true);
      const values = getValues();

      // api call
      const apiUrl = process.env.GATSBY_API_PREFIX + "/Stripe/CreateRegistrationPayment";
      const { sessionId } = data;

      const response = await axios
        .post(`${apiUrl}?sessionId=${sessionId}&productId=${values.product}`)
        .catch((err) => {
          setLoading(false);
          setApiError(true);
          throw err;
        });

      if (response && response.data.success === false) {
        if (response.data.reason === "OtpNotValidated")
          setOtpNotValidated(true);
      }

      if (response && response.data.isEligible === true) {
        // get selected product info from products array
        let productName;
        let productPrice;
        products.forEach((product) => {
          if (product.productId === parseInt(values.product, 10)) {
            productName = product.name;
            productPrice = product.unitPrice;
          }
        });

        // get the address details
        const addressDetails = await getAddressDetails();

        // save form data to state
        setData({
          ...data,
          productId: values.product,
          productName,
          productPrice,
          id: response.data.id,
          isEligible: response.data.isEligible,
          clientSecret: response.data.clientSecret,
          restaurantDisplayAddress: addressDetails.Label.replaceAll("\n", ","),
          address1: addressDetails.Line1,
          address2: addressDetails.Line2,
          town: addressDetails.City,
          county: addressDetails.ProvinceName,
          country: addressDetails.CountryName,
          postcode: addressDetails.PostalCode,
        });

        // if all ok move to next step
        if (response) setStep((cur) => cur + 1);
      }

      setLoading(false);
    }
  };

  return (
    <section className={step === 4 ? "form-section active" : "form-section"}>
      <h2>Product Selection</h2>

      <div className={errors.product ? "has-error" : ""}>
        {products.map((product) => (
          <div className="form-radio-group" key={product.productId}>
            <div className="form-radio">
              <input
                className="form-radio-control"
                type="radio"
                name="product"
                id={product.htmlId}
                value={product.productId}
                {...register("product", { required: true })}
              />
              <label className="form-radio-label" htmlFor={product.htmlId}>
                {product.name}
              </label>
            </div>
            <small className="form-text">
              {product.description} for{" "}
              <strong>£{product.unitPrice} a year</strong>.
            </small>
          </div>
        ))}

        {otpNotValidated && (
          <small className="form-text error-text" role="alert">
            The OTP code was not validated.
          </small>
        )}
        {errors.product && (
          <small className="form-text error-text" role="alert">
            Please select a product.
          </small>
        )}
      </div>

      {apiError && <ApiError />}

      <footer>
        <BackButton step={step} setStep={setStep} />
        <ContinueButton onClick={createRegistrationPayment} />
      </footer>
    </section>
  );
};

export default Step4;
