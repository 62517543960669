import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../layouts/index";
import Register from "../components/Register";

// markup
export default function RegistrationPage() {
  return (
    <Layout>
      <Helmet title="Register with VIP Dine" />
      <div className="register">
        <section className="section hero reserved-hero sub-page">
          <div className="container">
            <div className="row stretch">
              <div className="col">
                <h1>Registration</h1>
              </div>

              <div className="col">
                <div className="panel">
                  <Register />
                </div>
              </div>
            </div>
          </div>
          <div className="overlay"></div>
        </section>
      </div>
    </Layout>
  );
}
